import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import './App.css';

const App = () => {
  const [menu, setMenu] = useState([]);
  const [filters, setFilters] = useState({ category: 'all' });
  const [view, setView] = useState('home'); // 'home', 'menu'
  const [currentType, setCurrentType] = useState(''); // 'food' or 'drinks'

  useEffect(() => {
    fetch(
      'https://script.googleusercontent.com/macros/echo?user_content_key=CmnaKZyhkY7mOTAjsY_z_DGYfgJFDReQYTBmaKA4Zw44bX2QzZIUW7VAS_8w-Tfq7jQoqFdj7Tk5oXD1okYyzngQ0OAJzN8om5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnH8dFBC7SS1I0lSckwOpxDdWrzATfb5TcMSRkYCcys6lbKcFOJVrjIzxon1tJNwBaPEMFWsWjml3qchkvjzMnFJTxISIA3Cz9A&lib=M_0VjoImE4PW6sTj1sOGtlqiY_WVQgYmc'
    )
      .then((response) => response.json())
      .then((data) => setMenu(data.data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // Function to get the background gradient based on category
  const getCardBackground = (category) => {
    if (category === 'veg') return 'bg-gradient-to-br from-green-100 to-green-200';
    if (category === 'non-veg') return 'bg-gradient-to-br from-red-100 to-red-200';
    return 'bg-gradient-to-br from-gray-100 to-gray-200'; // default for other categories
  };

  // Handle filtered menu for the selected type
  const filteredMenu = menu.filter((item) => {
    const typeMatch = currentType ? item.type === currentType : true;
    const categoryMatch = filters.category === 'all' || item.category === filters.category;
    return typeMatch && categoryMatch;
  });

  if (view === 'home') {
    return (
      <div className="bg-gradient-to-br from-green-300 to-blue-300 min-h-screen flex flex-col justify-between">
        <header className="bg-gradient-to-r from-blue-600 to-green-600 text-white p-6 text-center text-5xl font-extrabold shadow-md">
          MEAL<span className="text-yellow-300"> ON </span>WHEEL
        </header>
        <main className="flex flex-col items-center justify-center flex-grow space-y-8">
          <button
            className="w-3/4 md:w-1/3 p-6 bg-gradient-to-r from-green-500 to-green-700 text-white rounded-lg shadow-lg text-2xl font-bold hover:scale-105 transform transition duration-300"
            onClick={() => { setView('menu'); setCurrentType('food'); }}
          >
            EXPLORE FOOD
          </button>
          <button
            className="w-3/4 md:w-1/3 p-6 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-lg shadow-lg text-2xl font-bold hover:scale-105 transform transition duration-300"
            onClick={() => { setView('menu'); setCurrentType('drinks'); }}
          >
            EXPLORE DRINKS
          </button>

          {/* Payment Section on Home Page */}
          <div className="bg-white p-5 shadow-lg mt-10 w-3/4 md:w-1/3">
            <h3 className="text-xl font-bold text-center mb-4">Make a Payment</h3>
            <button
              onClick={() => window.location.href = 'upi://pay?pa=yourupi@upi&pn=MealOnWheel&mc=123456&tid=12345&url=http://mealonwheel.com'}
              className="w-full p-4 bg-gradient-to-r from-green-500 to-green-700 text-white rounded-lg shadow-lg text-2xl font-bold hover:scale-105 transform transition duration-300"
            >
              Pay Now (UPI)
            </button>
            <div className="text-center mt-4">
              <span>Your UPI ID: <strong>yourupi@upi</strong></span>
            </div>
          </div>

        </main>
        <footer className="bg-gray-800 text-white text-center p-4 mt-auto">
          &copy; {new Date().getFullYear()} MEAL ON WHEEL. ALL RIGHTS RESERVED.
        </footer>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <header className="bg-gradient-to-r from-blue-600 to-green-600 text-white p-6 shadow-md relative">
        <div className="flex justify-between items-center">
          <button
            className="p-3 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 shadow-md flex items-center"
            onClick={() => setView('home')}
          >
            <FaArrowLeft />
          </button>
          <div className="flex space-x-4">
            <button
              className={`p-2 px-4 rounded-lg shadow-md font-bold ${filters.category === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
              onClick={() => setFilters({ category: 'all' })}
            >
              ALL
            </button>
            <button
              className={`p-2 px-4 rounded-lg shadow-md font-bold ${filters.category === 'veg' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
              onClick={() => setFilters({ category: 'veg' })}
            >
              VEG
            </button>
            <button
              className={`p-2 px-4 rounded-lg shadow-md font-bold ${filters.category === 'non-veg' ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
              onClick={() => setFilters({ category: 'non-veg' })}
            >
              NON-VEG
            </button>
          </div>
        </div>
      </header>
      <main className="p-5 flex-grow">
        {filteredMenu.length === 0 ? (
          <div className="text-center text-gray-600 text-xl font-bold py-10">
            No items match your selection. Please try adjusting the filters.
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredMenu.map((item) => {
              if (!item.available) return null; // Prevent rendering unavailable items
              return (
                <div
                  key={item.id}
                  className={`${getCardBackground(item.category)} rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300`}
                >
                  <img
                    src={item.type === 'food' ? '/images/food.webp' : '/images/drinks.webp'}
                    alt={item.name}
                    className="w-full h-56 object-cover"
                  />
                  <div className="p-5">
                    <h2 className="text-2xl font-extrabold text-gray-800 mb-2 uppercase text-center">
                      {item.name}
                    </h2>
                    <p className="text-sm text-gray-600 capitalize">CATEGORY: {item.category}</p>
                    {item.description && (
                      <p className="text-gray-700 mt-2 uppercase">{item.description}</p>
                    )}
                    <div className="flex justify-between items-center mt-4">
                      {(item.half_price || item.full_price) && (
                        <span className="text-green-600 font-bold">
                          {item.half_price && `HALF: ₹${item.half_price}`}
                          {item.full_price && `/ FULL: ₹${item.full_price}`}
                        </span>
                      )}
                      <span
                        className={`px-3 py-1 text-sm font-semibold rounded-full ${item.available
                          ? 'bg-green-200 text-green-800'
                          : 'bg-red-200 text-red-800'
                          }`}
                      >
                        {item.available ? 'AVAILABLE' : 'UNAVAILABLE'}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </main>
      <footer className="bg-gray-800 text-white text-center p-4 mt-auto">
        &copy; {new Date().getFullYear()} MEAL ON WHEEL. ALL RIGHTS RESERVED.
      </footer>
    </div>
  );
};

export default App;
